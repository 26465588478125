@import '@styles/mixins.style';

.acf-why-book-at {
  position: relative;
  background-color: var(--color-primary);
  padding-top: var(--ra-spacing-7);
  padding-bottom: var(--ra-spacing-7);
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 24px;
    background-color: var(--color-bg-light);
    mask: url('/assets/icons/navbar-torn-edge.svg');
    mask-position: top left;
    mask-size: contain;
    mask-repeat: repeat-x;
    pointer-events: none;
  }

  &::before {
    top: -1px;
  }

  &::after {
    bottom: -1px;
    transform: rotate(180deg);
  }

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--ra-spacing-4);
    margin-bottom: var(--ra-spacing-6);
    @include breakpoint(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    @include breakpoint-max(md) {
      button {
        display: none;
      }
    }
  }

  &__columns {
    display: flex;
    flex-direction: column;
    gap: var(--ra-spacing-4);

    @include breakpoint(md) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoint(xl) {
      margin-right: 300px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: var(--color-white);
    a {
      text-decoration: underline;
      color: var(--color-white) !important;

      &:hover {
        text-decoration: none;
      }
      &:focus-visible {
        outline: var(--ra-focus-outline) var(--color-white);
      }
    }
  }

  &__footer {
    margin-top: var(--ra-spacing-5);
    @include breakpoint(md) {
      display: none;
    }
  }

  &__checkmark {
    margin-bottom: var(--ra-spacing-4);
  }
}
